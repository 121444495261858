<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
      v-if="serverData"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7"
        >
          <form class="row ma-0 max-h-300px">
            <div class="col-12 col-sm-6 h-75px">
              <v-autocomplete
                v-model="formData.customer_id"
                label="Customer"
                :items="serverData.customers"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                :error-messages="customer_idErrors"
                @blur="$v.formData.customer_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 h-75px">
              <v-autocomplete
                v-model="formData.warehouse_id"
                label="Warehouse"
                :items="serverData.warehouses"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                :error-messages="warehouse_idErrors"
                @blur="$v.formData.customer_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 h-75px">
              <v-text-field
                v-model="formData.qty"
                label="Quantity"
                type="number"
                dense
                outlined
                clearable
                :error-messages="qtyErrors"
                @blur="$v.formData.qty.$touch()"
                @change="() => validateMinValue('qty', 0)"
              />
            </div>
            <div class="col-12 col-sm-6 h-75px">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.used_date"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    :error-messages="used_dateErrors"
                    @input="$v.formData.used_date.$touch()"
                    @blur="$v.formData.used_date.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.used_date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </form>

          <!--begin::Actions-->
          <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitForm"
            >
              {{ submitButtonText }}
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  components: {},
  validations: {
    formData: {
      customer_id: { required },
      qty: { required },
      used_date: { required },
      warehouse_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    formData: {
      id: null,
      customer_id: null,
      qty: null,
      used_date: null,
      warehouse_id: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.dialog = false;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = true;
      }
    },
    resetFormData() {
      this.formData = {
        id: null,
        customer_id: null,
        qty: null,
        used_date: null,
        warehouse_id: null,
      };
    },
    loadDataFromServer() {
      let url = "/warehouse_management/pallet_usages/create";
      let data = "";
      if (this.actionType) {
        data = { id: this.$store.getters.getItemForAction.id };
        url = "/warehouse_management/pallet_usages/edit";
      }
      this.pageLoader(true);
      ApiService.post(url, data)
        .then(({ data }) => {
          this.serverData = data;
          if (this.actionType) this.formData = data.pallet_usage;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(
        `/warehouse_management/pallet_usages/${actionUrl}`,
        this.formData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },

    customer_idErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    qtyErrors: function () {
      return this.handleFormValidation("qty");
    },
    used_dateErrors: function () {
      return this.handleFormValidation("used_date");
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id");
    },
  },
};
</script>
